import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

const App = () => (
  <Router>
    <Switch>
      {/* Rota para redirecionar todas as chamadas da judah.tv para meupastor.tv */}
      <Route path="/:path" render={({ match }) => (
        <Redirect to={`https://meupastor.tv/${match.params.path}`} />
      )} />
    </Switch>
  </Router>
);

export default App;
